/* WHOLE PAGE 
============= */
html {
	/*background-color: #fffffb;*/
	background-color: #000;

	position: relative;
	min-height: 100vh;
}
html, body {
	margin: 0;
	width: 100%;

	overflow: hidden;
}


/* GENERIC
========== */
a.unstyle-link, .unstyle-link a {
    color: inherit;
    text-decoration: none;
}
a.unstyle-link:hover, a.unstyle-link:focus, .unstyle-link a:hover, , .unstyle-link a:focus {
    color: inherit;
    text-decoration: none;
}
.antialias {
	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}
.line {
	display: inline-block;
}
.mobile-only {
	display: none;
}
picture > * {
	display: block;
	width: inherit;
	height: inherit;
	max-width: inherit;
	min-width: inherit;
}

.text-header { font-family: 'Lexend', 'Montserrat', Helvetica, sans-serif }
.text-sans { font-family: 'Trebuchet MS', Helvetica, sans-serif }
.text-sans-web { font-family: 'Trebuchet MS', Helvetica, sans-serif }
.text-mono { font-family: 'Courier New', Courier, monospace; }

.text-small { font-size: 12px; }
.text-regular { font-size: 16px; }
.text-medium { font-size: 24px; }
.text-large {font-size: 32px; }

.text-black { color: #080808; }
.text-white { color: #fffff3; }
.text-gold { color: #bca20f; }
.text-blue { color: #001434; }

.bg-black { background-color: #000; }
.bg-white { background-color: #fffff6; }
.bg-gold { background-color: #bca20f; }
.bg-blue { background-color: #001434; }

.no-select {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}


/* NAV BAR
========== */
#navBar {
	position: fixed;
	left: 0;
	width: 100vw;
	margin: 0;
	background-color: #000;

	padding: 8px 4vw;
	box-sizing: border-box;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	z-index: 100;

	top: -50px;
	opacity: 0;
	transition-duration: 0.5s;
	transition-timing-function: ease-out;
	transition-delay: 1.5s;
	transition-property: top, opacity;
}
html.loaded #navBar {
	top: 0;
	opacity: 1;
}

#navBar #navOptions {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

#navBar .nav-option {
	position: relative;
	padding: 4px 8px;
	margin: 0 8px;
	font-size: 20px;
	cursor: pointer;
}
#navBar .nav-option span {
	position: relative;
}
#navBar .nav-option span:after {
	content: '';
	position: absolute;
	top: 2px;
	left: 50%;
	width: 0;
	height: 100%;
	border-bottom: 2px solid #fff;
	box-sizing: border-box;
	pointer-events: none;

	transition: width 0.25s ease-out, left 0.25s ease-out;
}
#navBar .nav-option:hover span:after {
	width: 100%;
	left: 0;
}
#navBar #navOptions:not(:hover) .nav-option.selected-option span:after {
	width: 100%;
	left: 0;
}


/* GENERIC CONTENT
================== */
.content-page {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;

	visibility: hidden;
	opacity: 0;
	transition: opacity 0.5s ease-out 0s, visibility 0s linear 0.5s;
}
html.loaded .content-page.show-content {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.5s ease-out 0s, visibility 0s linear 0s;
}


/* HOME CONTENT
=============== */
#homeContent {
	height: 100vh;
	background-color: #000;
	transform: translateZ(0);
}
#homeContent.show-content {
	opacity: 1; /* to bypass the html.loaded requirement on other pages */
}
#homeContent .home-image-wrapper {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: 144.444vw;
}
#homeContent .home-image {
	position: absolute;
	display: block;
	width: 106%;
	right: 6%;
	bottom: min(0px, -45% + 50vh);
}
#homeContent .home-image img {
	opacity: 0;
	transition-duration: 2.0s;
	transition-timing-function: ease-out;
	transition-delay: 0.0s;
	transition-property: opacity;
}
#homeContent .home-image img.image-loaded {
	opacity: 1;
}

#homeContent .movie-title-section {
	position: absolute;
	top: max(60px, 10%);
	right: 12%;
	text-shadow: 0px 0px 9px #fff, 0px 0px 18px #fff, 0px 0px 36px #fff;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
}
#homeContent .movie-title {
	display: block;
	position: relative;
	margin: 0;
	font-weight: 400;
	font-size: 64px;
	line-height: 60px;
	letter-spacing: -6px;

	align-self: center;

	opacity: 0;
	transition-duration: 1.0s;
	transition-timing-function: ease-out;
	transition-delay: 0.5s;
	transition-property: opacity;
}
html.loaded #homeContent .movie-title {
	opacity: 1;
}

#homeContent .movie-title span {
	display: inline-block;
	width: 100%;
}
#homeContent .movie-title b {
	color: rgb(19, 31, 67);
}

#homeContent .movie-subtitle {
	position: relative;
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 3px;
	margin: 0;

	padding-top: 20px;

	align-self: flex-end;

	bottom: -20px;
	opacity: 0;
	transition-duration: 0.75s;
	transition-timing-function: ease-out;
	transition-delay: 1.0s;
	transition-property: bottom, opacity;
}
html.loaded #homeContent .movie-subtitle {
	bottom: 0;
	opacity: 1;
}

#homeContent .movie-cast-section {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	position: relative;
	padding-bottom: 30px;

	top: -20px;
	opacity: 0;
	transition-duration: 0.75s;
	transition-timing-function: ease-out;
	transition-delay: 1.25s;
	transition-property: top, opacity;
}
html.loaded #homeContent .movie-cast-section {
	top: 0;
	opacity: 1;
}

#homeContent .movie-cast-section .cast-name {
	text-align: center;
	font-weight: 700;
	padding: 0 8px;
}
#homeContent .movie-cast-section .cast-name span {
	display: block;
}
#homeContent .movie-cast-section .cast-name span.first-name {
	color: rgb(92, 53, 25);
	font-size: 16px;
}
#homeContent .movie-cast-section .cast-name span.last-name {
	color: rgb(59, 76, 98);
	font-size: 20px;
	position: relative;
	top: -4px;
}


/* SYNOPSIS CONTENT
=================== */
#synopsisContent {
	padding-top: 60px;
	box-sizing: border-box;
	background-color: #000;

	height: 100vh;
	overflow-y: scroll;
}

#synopsisContent .synopsis-information {
	position: relative;
	margin: auto;
	text-align: center;
	padding: 0 16px;
	max-width: 800px;
}

#synopsisContent h1 {
	font-size: 36px;
	color: #efc76f;
}

#synopsisContent p {
	font-size: 20px;
	text-align: start;
	letter-spacing: 0.25px;
	color: #fff;
}


/* VIDEOS CONTENT
================= */
#videosContent {
	padding-top: 60px;
	box-sizing: border-box;
	background-color: #000;
	color: #efc76f;

	height: 100vh;
	overflow-y: scroll;
}

#videosContent .videos-information {
	position: relative;
	margin: auto;
	text-align: center;
	max-width: 800px;
}

.video-wrapper {
	width: 100%;
	max-width: 95vw;
}
.video-wrapper:after {
	content: '';
	display: block;
	position: absolute;
	top: -1px;
	left: -1px;
	bottom: -1px;
	right: -1px;
	border: 1px solid #efc76f;
	pointer-events: none;
}

.video-wrapper.video-16x9 {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;

	margin: 40px auto;
}

.video-wrapper.video-12x7 {
	position: relative;
	padding-bottom: 58.33%; /* 12:7 */
	height: 0;

	margin: 40px auto;
}

.video-wrapper iframe {
	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  	pointer-events: all;
}


/* PRESS CONTENT
================ */
#pressContent {
	padding-top: 60px;
	box-sizing: border-box;
	background-color: #000;

	height: 100vh;
	overflow-y: scroll;
}

#pressContent .press-information {
	position: relative;
	margin: auto;
	text-align: center;
	/*padding: 0 8px;*/
}

#pressContent h1 {
	font-size: 36px;
	color: #efc76f;
}

#pressContent .press-links a {
	position: relative;
	display: block;
	max-width:  800px;
	font-size: 20px;
	margin: 32px auto;
	padding: 12px 20px;
	text-decoration: underline;
	/*border: 2px solid #fff;*/
	box-sizing: border-box;
	color: #fff;
}
#pressContent .press-links a span:not(.press-attrib) {
	text-decoration: underline;
}
#pressContent .press-links a .press-attrib {
	display: inline-block;
	width: 100%;
	text-align: right;
	position: relative;
	right: -10px;
	text-decoration: none;
	font-style: italic;
}
#pressContent .press-links a:hover {
/*	background-color: #fff;*/
	/*color: #000;*/
	color: #ccc;
}


/* FOOTER 
========= */
#footer {
	position: fixed;
	width: 100%;
	left: 0;
	bottom: 0;
	padding: 6px 0;

	background: linear-gradient(0, rgba(0,0,0,0.25), rgba(0,0,0,0));

	text-align: center;
	font-weight: lighter;
	font-size: 8px;
	color: rgba(255,255,255,0.6);

	opacity: 0;
	transition: opacity 1.0s ease-out 1.0s;
}
html.loaded #footer {
	opacity: 1;
}



/* MEDIA QUERIES
================ */

/* LARGER THAN HOME IMAGE WIDTH */
/*@media only screen and (min-width: 1945px) {
	picture.home-image:after {
	    background: linear-gradient(90deg, rgba(255,255,255,0) 60%, rgba(255,255,255,1) 95%);
	    display: block;
	    content: '';
	    position: absolute;
	    width: 105%;
	    height: 100%;
	    top: 0;
	    left: 0;
	}
}*/

@media only screen and (max-width: 1079px) {
	#homeContent .movie-title-section {
		right: 8%;
	}
}

@media only screen and (max-width: 959px) {
	#homeContent .movie-title-section {
		right: 4%;
	}
}

@media only screen and (max-width: 839px) {
	#homeContent .movie-title-section .movie-title {
		font-size: 48px;
		line-height: 40px;
	}
	#homeContent .movie-title-section .movie-subtitle {
		font-size: 10px;
	}
	#homeContent .movie-cast-section  {
		padding-bottom: 10px;
	}
	#homeContent .movie-cast-section .cast-name span.first-name {
		font-size: 10px;
	}
	#homeContent .movie-cast-section .cast-name span.last-name {
		font-size: 14px;
	}
}

@media only screen and (max-width: 569px) {
	#navBar, #navBar #navOptions {
		justify-content: center;
	}
	#navBar .nav-option {
		font-size: 14px;
		padding: 4px 16px;
		margin: 0;
	}
}

/* MOBILE STARTS HERE 
===================== */
@media only screen and (max-width: 529px)  {
	#navBar {
		justify-content: center;
	}
	#navBar .nav-option {
		font-size: 14px;
		padding: 4px 16px;
		margin: 0;
	}

	#homeContent .movie-title-section {
		left: 0;
		right: 0;
	}
	#homeContent .movie-title-section .movie-title {
		font-size: 36px;
		line-height: 30px;
		letter-spacing: -4px;
	}
	#homeContent .movie-title-section .movie-subtitle {
		align-self: center;
		padding-top: 10px;
	}
	#homeContent .movie-cast-section {
		justify-content: space-around;
		padding-top: 10px;
	}

	.video-wrapper {
		max-width: unset;
	}
	.video-wrapper:after {
		border-left: none;
		border-right: none;
	}
}

@media only screen and (max-width: 499px) {
	#homeContent .home-image-wrapper {
		height: 100vh !important;
	}
	#homeContent .home-image {
		bottom: 0 !important;
		right: 0;
		width: 100vw;
	}
	#homeContent .home-image > * {
		object-fit: cover;
		height: 100vh;
	}
}

@media only screen and (max-width: 415px) {
	#navBar .nav-option {
		font-size: 12px;
		padding: 4px 8px;
	}
	#pressContent .press-links a {
		font-size: 16px;
	}
}
